<template>
    <div class="course-finish">
        <button
            type="button"
            class="btn-block-option close-modal"
            @click="$modal.hide('course-finish')"
        >
            ×
        </button>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="block-content content">
                    <div class="course-finish__wrapper">
                        <div class="course-finish__feedback">
                            <div class="course-finish__feedback-icon"></div>
                            <h3 class="course-finish__title">Вы успешно<br> прошли курс!</h3>
                            <ValidationObserver tag="form" class="course-finish__form" @submit.prevent="sendReview" ref="observer" method="post">
                                <ValidationProvider rules="required" vid="review" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label class="course-finish__label">
                                            Оставьте отзыв об обучении
                                        </label>

                                        <div class="course-finish__textarea">
                                            <v-textArea
                                                :name="`review`"
                                                :max-length="100"
                                                :value="reviewText"
                                                @input="value => reviewText = value"
                                            />
                                        </div>

                                        <div id="id_review-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <button class="v-btn" type="submit">Отправить</button>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import session from "@/api/session";

export default {
    name: 'ModalCourseFinish',
    components: {
        vTextArea : () => import('@/components/TextArea')
    },
    data() {
        return {
            reviewText: ''
        }
    },
    methods: {
        async sendReview() {
            const isValid = await this.$refs.observer.validate();

            if (isValid) {
                try {
                    const request = session.post(`/api/v1/course/${this.$route.params.course_id}/review/`, {
                        review: this.reviewText
                    });

                    const response = await request;

                    this.$modal.hide('course-finish');
                    const ThanksFeedback = () => import("@/components/course/modals/ThanksFeedback");
                    this.$modal.show(
                        ThanksFeedback,
                        {},
                        {
                            name: "thanks-feedback",
                            adaptive: true,
                            maxWidth: 528,
                            width: "100%",
                            height: "auto",
                        }
                    );
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .v--modal-overlay .v--modal-box {
        overflow: visible;
    }

    .course-finish {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 520px;
        padding: 0;

        color: $black;
        background-color: $white;

        @include below($mobile) {
            min-height: 0;
            padding: 40px 0;
            margin-bottom: 20px;
        }

        .close-modal {
            //top: -13px;
            //right: -42px;
        }

        .modal-dialog {
            margin-bottom: 45px;

            @include below($mobile) {
                width: 100%;
                margin-bottom: 30px;
            }
        }


        &__wrapper {
            text-align: center;
        }

        &__feedback-icon {
            margin: 0 auto 30px;
            @include icon($finished, 56px);

            @include below($mobile) {
                margin-bottom: 30px;

                background-size: 56px;
            }
        }

        &__thanks-icon {
            margin: 0 auto 30px;
            @include icon($thanks, 84px);

            @include below($mobile) {
                margin-bottom: 30px;

                background-size: 56px;
            }
        }

        &__title {
            margin-bottom: 21px;

            font-size: 22px;
            line-height: 30px;

            @include below($mobile) {
                width: 80%;
                margin: 0 auto 20px;

                font-size: 22px;
                line-height: 30px;
            }
        }

        &__label {
            display: block;
            margin-bottom: 29px;

            font-size: 16px;
            line-height: 24px;
            font-weight: 600;

            @include below($mobile) {
                margin-bottom: 27px;

                font-size: 16px;
                line-height: 24px;
            }
        }

        &__textarea {
            margin: 0 auto 20px;
        }
    }

</style>
